body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-container {
  border: 2px solid #d3d3d3;
  border-radius: .5em;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  padding: 1em;
  text-align: left;
  width: 600px;
}

.form {
  padding: 1em;
}

label {
  font-weight: 600;
}


.MUIFormControl-root, MuiInputBase-input {
  padding: 50px;
}


.debug-logs-text,
.debug-logs-stackTrace
{
  white-space: pre-wrap ;
}