html,
body,
#root
{
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}

#root
{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: "PT Sans";

}


/******************************************************************************
 * TODO
 ******************************************************************************/

p
{
    margin-bottom: 0.5em;
}
h1
{
    margin-top: 0;
    color: #5793B5;
}
h2
{
    color: #5793B5;
    margin-bottom: 0;
}

.page-header
{
    border-bottom: 1px solid #5793B5;
    padding-bottom: 2em;
    display: flex;
    flex-direction: row;
}

hr
{
    border: none;
    border-bottom: 1px solid #5793B5;
    padding-bottom: 2em;
}

.page-header-1
{
    flex: 1;
}

.page-header-icon
{
    flex: 0;
    margin-top: -3em;
}

.icon 
{
    margin-top: 3em;
    max-width: 200px;
    max-height: 100px;
}

.practice-form-wrapper 
{
    margin-bottom: 3em;
}

/******************************************************************************
 * Main elements
 ******************************************************************************/

.layout-container
{
    display: flex;
    flex: 1;
    max-height: calc(100% - 80px);

}

#main-container
{
    flex: 1;
    background-image: url("./images/Background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}


/******************************************************************************
 * Full page content
 ******************************************************************************/

#content-full
{
    flex: 1;
    display: flex;
}

#content-full-content
{
    background-color: #E2ECF2CF;
    padding: 50px;
    margin: 20px;
    color: #3D454D;
    flex: 1;
    padding-bottom: 4em;
    margin-bottom: -2em;
}

#content-full-footer
{
    color: #5793B5;
    font-size: 2em;
    position: relative;
    bottom: 1em;
    text-align: center;
}


/******************************************************************************
 * Small content
 ******************************************************************************/

#content-small
{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
#content-small-content
{
    background-color: #E2ECF2CF;
    padding: 30px 50px 50px 50px;
    color: #3D454D;
    border-radius: 12px;
    max-width: 28em;
    min-width: 28em;
}

#content-small-footer
{
    color: #5793B5;
    margin: auto;
    margin-bottom: 3em;
    font-size: 2em;
}

/******************************************************************************
 * Links & buttons
 ******************************************************************************/

a
{
    text-decoration: none;
    color: #5793B5;
}

.link-button
{
    background-color: #5593B1;    
    color: #FFFFFF;
    border-radius: 4px;
    padding: 12px;
    display: inline-block;
    min-width: 180px;
}
.link-button a
{
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: smaller;
    font-weight: bolder;
}
.link-button ::after
{
    content: '\27F6';
    float: right;
    padding-left: 40px;
}

/******************************************************************************
 * Forms
 ******************************************************************************/

#content-full-content .form-wrapper
{
    max-width: 50%;
}

.MuiFormControl-root
{
    margin: 0;
    min-height: 5em;
}

.MuiFormHelperText-root.Mui-error
{
    margin-left: 12px;
}

.MuiFormLabel-root 
{
    z-index: 1;
    margin-top: 4px;
    margin-left: 12px;
    color: #878787 !important;
}

.MuiInputLabel-shrink
{
    color: #5793B5 !important;
    margin-top: -4px;
}

.MuiInput-root
{
    background-color: white;
    border-radius: 8px;
    padding: 4px;
    padding-left: 12px;
}
.MuiInput-root.Mui-error
{
    border: solid 1px red;
}

.MuiInput-root.MuiInputBase-multiline
{
    padding: 4px;
    padding-left: 12px;
}

.CRUDFilter .MuiInputBase-root
{
    padding: 0 !important;
    margin-top: 16px !important;
}

.CRUDFilter input
{
    padding: 0 !important;
    margin-top: 16px !important;
}

.CRUDFilter label
{
    padding: 0 !important;
    margin-top: 8px !important;
    margin-left: 0 !important;
}

/******************************************************************************
 * Forms - double[]
 ******************************************************************************/
 .double_a_wrapper
 {
    width: 100%;
 }

 .double_a_wrapper .MuiTextField-root
 {
    width: 100%;
 }

 .double_a_wrapper .MuiTextField-root input
 {
    padding-bottom: 2.2em;
 }
 
 .double_a_elements
 {
     min-height: 1em;
     position: relative;
     top: 5.5em;
     z-index: 2;
     left: 12px;
 }

 .double_a_elements li
 {
    display: inline;
    padding-left: 0;
 }
 
/******************************************************************************
 * Forms - Image control
 ******************************************************************************/

.image-control .uploader__placeholder
{
    background: white;
    border-radius: 8px;
    min-height: 55px;
    max-height: 55px;
    min-width: 200px;
    max-width: 200px;
    padding-left: 10px;
    padding-right: 40px;
    position: relative;
}

.image-control .uploader__container
{
   margin: 16px 0 4px 0;
}

.uploader__btn_wrapper
{
    width: 25px;
    position: absolute;
    right: 4px;
    bottom: 0px;
    top: auto;
}

.uploader__file_input_label
{
    width: 25px;
    position: absolute;
    left: auto;
    right: 4px;
    top: 4px;
    transform: none;
}

.uploader__file_input
{
    display: none;
}

.uploader__file
{
    position: absolute;
    left: 10px;
    top: 0;
    max-width: 200px;
    max-height: 55px;
}


/******************************************************************************
 * Tables
 ******************************************************************************/

.value-label-image img
{
    max-height: 1.5em;
}

/******************************************************************************
 * Unsorted
 ******************************************************************************/

#version-info
{
    background-color: #F2F2F2;
    border: dashed black 1px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.MuiButton-root
{
    font-size: smaller !important;
    font-weight: bolder !important;
    min-width: 180px !important;
}

.MuiButton-root::after,
.MuiButton-root:disabled::after
{
    content: '\27F6' !important;
    float: right;
    padding-left: 40px;
    min-width: 40px !important;
}

/******************************************************************************
 * Header
 ******************************************************************************/

#header
{
    background-color: #E2ECF2;
    height: 80px;
    padding: 0;
}

#logo
{
    height: 70px;
    margin: 5px 30px ;
}

.user
{
    color: #5693B2;
    vertical-align: middle;
    margin: 15px;
}

.user-wrapper
{
    position: relative;
    float: right;
}

/******************************************************************************
 * Settings
 ******************************************************************************/

 .settings-main
 {
    display: flex;
    flex-direction: row;
    width: 500px;
 }

 .settings-main .sidebar-container
 {
    min-width: 250px;
 }

 .settings-main .side-navigation-panel-select-option-text
 {
    color: black;
 }

 .settings-container
 {
    background-color: red;
    flex: 1;
    height: 300px;
 }
 
/******************************************************************************
 * Navigation tabs
 ******************************************************************************/

 .nav-container
 {
    min-width: 250px;
    height: 100%;
    background-color: #393A39;
 }
 
 .nav-bottom-entry
 {
     position: absolute;
     bottom: 0;
 }

.side-navigation-panel-select-option-text,
.side-navigation-panel-select-inner-option-text
{
    color: white;
    text-transform: uppercase;
}

.side-navigation-panel-select-option > svg,
.side-navigation-panel-select-inner-option > svg
{
    fill: white;
}

.side-navigation-panel-select-option-selected
{
    background-color: transparent !important;
}

.side-navigation-panel-select-option,
.side-navigation-panel-select-inner-option
{
    border-bottom: solid #A7A9AC 1px !important;
    border-left: solid transparent 3px !important;
}

.side-navigation-panel-select-option
{
    height: 80px;
}

.side-navigation-panel-select-inner-option
{
    height: 60px;
}

.side-navigation-panel-select-option:hover,
.side-navigation-panel-select-option:focus,
.side-navigation-panel-select-option-selected,

.side-navigation-panel-select-inner-option:hover,
.side-navigation-panel-select-inner-option:focus,
.side-navigation-panel-select-inner-option-selected
{
    background-color: transparent !important;
    border-bottom: solid #A7A9AC 1px !important;
    border-left: solid #47A2CA 3px !important;
}


/******************************************************************************
 * Dialogs
 ******************************************************************************/

 .MuiDialog-paper
 {
    background-color: #E2ECF2 !important;
    max-width: 50% !important;
 }